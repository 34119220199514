import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  // {
  //   path: "/test", // 测试
  //   redirect: "/reportManage/greyClothSheet"
  // },
  // {
  //   path: "/workOrderManage/gantt", //
  //   name: "Gantt",
  //   component: resolve => require(["../views/pages/workOrderManagement/gantt"], resolve),
  //   meta: {
  //     title: "甘特图",
  //     keepAlive: true
  //   }
  // },
  {
    path: "/",
    name: "Login",
    component: resolve => require(["../views/index/login.vue"], resolve)
  },
  {
    path: "/home",
    name: "Home",
    component: resolve => require(["../views/index/home.vue"], resolve),
    children: [
      {
        path: "/index", // 首页
        name: "Index",
        component: resolve => require(["../views/pages/index"], resolve),
        meta: {
          title: "首页"
        }
      },
      {
        path: "/workOrderManage/clothSheet", // 坯布指示单
        name: "ClothSheet",
        component: resolve => require(["../views/pages/clothSheet"], resolve),
        meta: {
          title: "坯布指示单",
          keepAlive: true
        }
      },
      {
        path: "/workOrderManage/clothSheetDetail", // 坯布生产指示单详情
        name: "ClothSheetDetail",
        component: resolve => require(["../views/pages/clothSheetDetail"], resolve),
        meta: {
          title: "坯布生产查询"
        }
      },
      {
        path: "/workOrderManage/productPlan", // 排产计划
        name: "ProductPlan",
        component: resolve => require(["../views/pages/workOrderManagement/productPlan"], resolve),
        meta: {
          title: "排产计划",
          keepAlive: true
        }
      },
      {
        path: "/workOrderManage/deviceView", // 下发计划任务(设备视图)
        name: "DeviceView",
        component: resolve => require(["../views/pages/workOrderManagement/deviceView"], resolve),
        meta: {
          title: "下发计划任务",
          keepAlive: true
        }
      },
      {
        path: "/outputManage/outputDispatch", // 排班计划(原量产派工)
        name: "OutputDispatch",
        component: resolve => require(["../views/pages/outputManagement/outputDispatch"], resolve),
        meta: {
          title: "排班计划",
          keepAlive: true
        }
      },
      {
        path: "/outputManage/productPlanHistory", // 排班历史
        name: "ProductPlanHistory",
        component: resolve =>
          require(["../views/pages/outputManagement/productPlanHistory"], resolve),
        meta: {
          title: "排班历史",
          keepAlive: true
        }
      },
      {
        path: "/warehouseManage/materialSearch", // 原料在库查询
        name: "MaterialSearch",
        component: resolve => require(["../views/pages/materialSearch"], resolve),
        meta: {
          title: "原料查询",
          keepAlive: true
        }
      },
      {
        path: "/warehouseManage/materialEntry", // 原料进仓
        name: "MaterialEntry",
        component: resolve => require(["../views/pages/materialEntry"], resolve),
        meta: {
          title: "原料进仓"
        }
      },
      {
        path: "/warehouseManage/materialOutput", // 原料出仓
        name: "materialOutput",
        component: resolve => require(["../views/pages/materialOutput"], resolve),
        meta: {
          title: "原料出仓"
        }
      },
      {
        path: "/warehouseManage/clothSearch", // 坯布查询
        name: "ClothSearch",
        component: resolve => require(["../views/pages/clothSearch"], resolve),
        meta: {
          title: "坯布查询",
          keepAlive: true
        }
      },
      {
        path: "/warehouseManage/clothEntry", // 坯布进仓
        name: "ClothEntry",
        component: resolve => require(["../views/pages/clothEntry"], resolve),
        meta: {
          title: "坯布进仓",
          keepAlive: true
        }
      },
      {
        path: "/warehouseManage/clothOutput", // 坯布出仓
        name: "ClothOutput",
        component: resolve => require(["../views/pages/clothOutput"], resolve),
        meta: {
          title: "坯布出仓",
          keepAlive: true
        }
      },
      {
        path: "/warehouseManage/productionSearch", // 成品查询
        name: "ProductionSearch",
        component: resolve => require(["../views/pages/productionSearch"], resolve),
        meta: {
          title: "成品查询"
        }
      },
      {
        path: "/warehouseManage/productionEntry", // 成品进仓
        name: "ProductionEntry",
        component: resolve => require(["../views/pages/productionEntry"], resolve),
        meta: {
          title: "成品进仓"
        }
      },
      {
        path: "/warehouseManage/productionOutput", // 成品出仓
        name: "ProductionOutput",
        component: resolve => require(["../views/pages/productionOutput"], resolve),
        meta: {
          title: "成品出仓"
        }
      },
      {
        path: "/productionExecute/packAndCheck", // 包装检验
        name: "packAndCheck",
        component: resolve => require(["../views/pages/packAndCheck"], resolve),
        meta: {
          title: "包装检验",
          keepAlive: true
        }
      },
      {
        path: "/productionExecute/adjustingMachine", // 调机
        name: "AdjustingMachine",
        component: resolve =>
          require(["../views/pages/productionExecute/adjustingMachine"], resolve),
        meta: {
          title: "调机",
          keepAlive: true
        }
      },
      {
        path: "/productionExecute/firstInspection", // 头坯（首检）
        name: "FirstInspection",
        component: resolve =>
          require(["../views/pages/productionExecute/firstInspection"], resolve),
        meta: {
          title: "头坯",
          keepAlive: true
        }
      },
      {
        path: "/productionExecute/massProduce", // 量产，原来的量产（指示单）页面
        name: "ProductPlanMonitor",
        component: resolve => require(["../views/pages/productionExecute/massProduce"], resolve),
        meta: {
          title: "量产",
          keepAlive: true
        }
      },
      {
        path: "/productionExecute/completeProduction", //   指定完工，原来的量产（机台）页面
        name: "CompleteProduction",
        component: resolve =>
          require(["../views/pages/productionExecute/completeProduction"], resolve),
        meta: {
          title: "指定完工",
          keepAlive: true
        }
      },

      {
        path: "/productionExecute/massProduce", // 量产
        name: "MassProduce",
        component: resolve => require(["../views/pages/productionExecute/massProduce"], resolve),
        meta: {
          title: "量产",
          keepAlive: true
        }
      },
      {
        path: "/account/account", // 账户设置
        name: "account",
        component: resolve => require(["../views/pages/account/account"], resolve),
        meta: {
          title: "账户设置"
        }
      },
      {
        path: "/dyeingAndFinishing/dyeingAndFinishingOrder", // 染整列表(原染整跟单)
        name: "dyeingAndFinishingOrder",
        component: resolve => require(["../views/pages/dyeingAndFinishingOrder"], resolve),
        meta: {
          title: "染整列表",
          keepAlive: true
        }
      },
      {
        path: "/dyeingAndFinishing/dyeingAndFinishingOrderDetail", // 染整详情
        name: "dyeingAndFinishingOrderDetail",
        component: resolve => require(["../views/pages/dyeingAndFinishingOrderDetail"], resolve),
        meta: {
          title: "染整详情"
        }
      },
      {
        path: "/systemManagement/organizational", // 组织架构
        name: "organizational",
        component: resolve => require(["../views/pages/systemManagement/organizational"], resolve),
        meta: {
          title: "组织架构"
        }
      },
      {
        path: "/systemManagement/authority", // 权限管理
        name: "authority",
        component: resolve => require(["../views/pages/systemManagement/authority"], resolve),
        meta: {
          title: "权限管理"
        }
      },
      {
        path: "/systemManagement/addRole", // 新建/编辑角色
        name: "addRole",
        component: resolve => require(["../views/pages/systemManagement/addRole"], resolve),
        meta: {
          title: "新建/编辑角色"
        }
      },
      // 设备管理
      {
        path: "/deviceManage/check", // 设备台账
        name: "check",
        component: resolve => require(["../views/pages/deviceManage/check"], resolve),
        meta: {
          title: "设备台账"
        }
      },
      // 机台分组
      {
        path: "/deviceManage/machinegroup", // 机台分组
        name: "group",
        component: resolve => require(["../views/pages/deviceManage/machineGroup"], resolve),
        meta: {
          title: "机台分组"
        }
      },
      // 机台分配
      {
        path: "/deviceManage/machineallocation", // 机台分配
        name: "allocation",
        component: resolve => require(["../views/pages/deviceManage/machineAllocation"], resolve),
        meta: {
          title: "机台分配"
        }
      },
      {
        path: "/deviceManage/addcheck", // 新建设备台账
        name: "addcheck",
        component: resolve => require(["../views/pages/deviceManage/addcheck"], resolve),
        meta: {
          title: "新建设备台账"
        }
      },
      {
        path: "/deviceManage/checkdetail", // 设备台账详情
        name: "checkdetail",
        component: resolve => require(["../views/pages/deviceManage/checkdetail"], resolve),
        meta: {
          title: "设备详情"
        }
      },
      {
        path: "/deviceManage/bulkimport", // 设备台账批量导入
        name: "bulkimport",
        component: resolve => require(["../views/pages/deviceManage/bulkimport"], resolve),
        meta: {
          title: "设备批量导入"
        }
      },
      {
        path: "/deviceManage/devmaintenance", // 设备保养
        name: "devmaintenance",
        component: resolve => require(["../views/pages/deviceManage/devmaintenance"], resolve),
        meta: {
          title: "计划保养"
        }
      },
      {
        path: "/deviceManage/addDevmaintenance", // 新增设备保养单
        name: "addDevmaintenance",
        component: resolve => require(["../views/pages/deviceManage/addDevmaintenance"], resolve),
        meta: {
          title: "新建设备保养单"
        }
      },
      {
        path: "/deviceManage/devmaintenanceDetail", // 查看设备保养单
        name: "devmaintenanceDetail",
        component: resolve =>
          require(["../views/pages/deviceManage/devmaintenanceDetail"], resolve),
        meta: {
          title: "查看设备保养单"
        }
      },
      {
        path: "/deviceManage/maintaining", // 实施保养
        name: "maintaining",
        component: resolve => require(["../views/pages/deviceManage/maintaining"], resolve),
        meta: {
          title: "实施保养"
        }
      },
      {
        path: "/deviceManage/maintainingDetail", // 查看实施保养
        name: "maintainingDetail",
        component: resolve => require(["../views/pages/deviceManage/maintainingDetail"], resolve),
        meta: {
          title: "查看实施保养"
        }
      },
      {
        path: "/deviceManage/addMaintaining", // 填写实施保养
        name: "addMaintaining",
        component: resolve => require(["../views/pages/deviceManage/addMaintaining"], resolve),
        meta: {
          title: "填写实施保养单"
        }
      },
      {
        path: "/deviceManage/record", // 设备故障记录
        name: "deviceRecord",
        component: resolve => require(["../views/pages/deviceManage/record"], resolve),
        meta: {
          title: "设备故障"
        }
      },
      {
        path: "/deviceManage/devMaintainRecord", // 设备保养记录
        name: "devMaintainRecord",
        component: resolve => require(["../views/pages/deviceManage/devMaintainRecord"], resolve),
        meta: {
          title: "设备保养记录"
        }
      },
      {
        path: "/deviceManage/recordDetail", // 设备故障记录
        name: "recordDetail",
        component: resolve => require(["../views/pages/deviceManage/recordDetail"], resolve),
        meta: {
          title: "故障详情"
        }
      },
      {
        path: "/reportManage/greyClothSheet", // （坯布）指示单报表
        name: "GreyClothSheet",
        component: resolve => require(["../views/pages/reportManage/greyClothSheet"], resolve),
        meta: {
          title: "指示单报表"
        }
      },
      {
        path: "/reportManage/outputSummary", // 产量汇总表
        name: "OutputSummary",
        component: resolve => require(["../views/pages/reportManage/outputSummary"], resolve),
        meta: {
          title: "产量汇总表"
        }
      },
      {
        path: "/reportManage/workReportQuery", // 报表查询
        name: "WorkReportQuery",
        component: resolve => require(["../views/pages/reportManage/workReportQuery"], resolve),
        meta: {
          title: "报表查询"
        }
      },
      {
        path: "/reportManage/clothQuery", // 疵布报表
        name: "ClothQuery",
        component: resolve => require(["../views/pages/reportManage/clothQuery"], resolve),
        meta: {
          title: "疵布报表"
        }
      },
      {
        path: "/reportManage/orderProgressQuery", // 订单进度报表
        name: "OrderProgressQuery",
        component: resolve => require(["../views/pages/reportManage/orderProgressQuery"], resolve),
        meta: {
          title: "订单进度报表"
        }
      }
    ]
  }
];

const router = new VueRouter({
  routes
});

export default router;
