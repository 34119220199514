import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  visitedViews: []
};

const mutations = {
  SETVIEWS: (state, view) => {
    state.visitedViews = view;
  },
  ADD_VISITED_VIEW: (state, view) => {
    if (state.visitedViews.some((v) => v.path === view.path)) return;
    if (view.meta.title.length > 5) {
      view.meta.title = view.meta.title.slice(0, 5) + "...";
    }
    state.visitedViews.push(
      Object.assign({}, view, {
        title: view.meta.title || "no-name"
      })
    );
  },
  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1);
        break;
      }
    }
  },
  DEL_ALL_VISITED_VIEWS: (state) => {
    const affixTags = state.visitedViews.filter((tag) => {
      if (tag.meta !== undefined) {
        return tag.meta.affix;
      }
    })
    state.visitedViews = affixTags;
  }
};
const actions = {
  // 异步写法，返回promise对象
  setView({ commit }, view) {
    commit("SETVIEWS", view);
  },
  addView({ commit }, view) {
    commit("ADD_VISITED_VIEW", view);
  },
  delView({ dispatch, state }, view) {
    return new Promise((resolve) => {
      dispatch("delVisitedView", view);
      resolve({
        visitedViews: [...state.visitedViews]
      });
    });
  },
  delVisitedView({ commit, state }, view) {
    return new Promise((resolve) => {
      commit("DEL_VISITED_VIEW", view);
      resolve([...state.visitedViews]);
    });
  },
  delAllVisitedViews({ commit, state }) {
    return new Promise((resolve) => {
      commit("DEL_ALL_VISITED_VIEWS");
      resolve([...state.visitedViews]);
    });
  }
};

export default new Vuex.Store({
  state,
  mutations,
  actions
});
