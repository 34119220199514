<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {
    var seen = [];
    var replacer = function(key, value) {
      if (value != null && typeof value === "object") {
        if (seen.indexOf(value) >= 0) {
          return;
        }
        seen.push(value);
      }
      return value;
    };
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem(
        "store",
        JSON.stringify(this.$store.state, replacer)
      );
    });
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
  },
  mounted() {
    this.setRem();
    window.onresize = this.setRem;
    window.addEventListener('storage', () => {
      if (window.location.path !== '/' && window.location.path !== '/#/'){
      localStorage.clear()
      this.$router.push('/')
      }
    })
  },
  methods: {
    setRem() {
      const w = window.innerWidth;
      document.documentElement.style.fontSize = w / 14.4 + "px";
    }
  },
   watch: {
    $route(to, from) {
      const routerName = this.$store.state.visitedViews.find(item => {
        return item.name === from.name;
      });
      if ("keepAlive" in from.meta) {
        if (routerName === undefined) {
          from.meta.keepAlive = false;
        } else {
          from.meta.keepAlive = true;
        }
      }
    }
  },
};
</script>
<style lang="scss">
#app {
  min-width: 1024px;
  font-family: Source Han Sans CN;
}
</style>
