import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import iView from "view-design";
import "view-design/dist/styles/iview.css";
import Axios from "axios";
import moment from "moment";
import qs from "qs";

import * as echarts from "echarts";
import global from "./global";

import "../src/assets/css/index.scss";
import "./assets/content_font/iconfont.css";

import Print from "vue-print-nb";

Vue.use(Print);
Vue.use(ElementUI, { size: 'small', zIndex: 9000 }); // 设置组件初始index，防止被其他组件覆盖
Vue.use(iView);

Vue.prototype.baseUrl = global.baseUrl;
Vue.config.productionTip = false;
Vue.prototype.axios = Axios;
Vue.prototype.echarts = echarts;
Vue.prototype.moment = moment;
Vue.prototype.qs = qs;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
